<template>
  <div class="treeLoading" style="height: calc(100% - 20px);max-height: calc(100% - 20px);overflow-y: auto;overscroll-behavior: contain">
    <!--    <vueScroll :ops="ops">-->
    <div style="display: flex;height: 45px;align-items: center;padding-left: 20px">
      <span>{{ '知识点' }}</span>
      <Button class="empty" shape="circle" @click="empty()"><span>清空</span></Button>
    </div>
    <hr style="border-color: #d2d2d2;border-bottom: 1px;opacity: 0.2" />
    <el-tree
      :data="data"
      ref="elTree"
      show-checkbox
      style="padding: 15px 0 0 20px"
      node-key="id"
      :default-checked-keys="checkedKnowSelec"
      :props="defaultProps"
      @check-change="getCheckedNodes"
    />
    <!--    </vueScroll>-->
  </div>
</template>

<script>
import question from '../../../../../api/question'
import vueScroll from 'vuescroll'
export default {
  name: 'drawByKnowData',
  components: { vueScroll },
  props: {
    knowledgeList: {
      type: Array,
      default: null
    },
    checkedKnowSelec: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      data: [],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#222',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectData: []
    }
  },
  mounted() {
    this.getKnowledge()
  },
  methods: {
    empty() {
      this.selectData = []
      this.getKnowledge()
      this.$emit('checkKnowNodes', this.selectData)
    },
    getKnowData() {
      this.getKnowledge()
    },
    getKnowledge() {
      let loading = this.$onLoading('.treeLoading')
      question
        .getKnownLedgeTree()
        .then(data => {
          this.sortData(data.res)
        })
        .finally(() => {
          loading.close()
        })
    },
    sortData(data) {
      if (data.length > 0) {
        data.forEach(d => {
          d.label = d.name
        })
      }
      this.data = data
    },
    getCheckedNodes() {
      this.selectData = this.$refs.elTree.getCheckedNodes()
      this.$emit('checkKnowNodes', this.selectData)
    }
  }
}
</script>

<style lang="less" scoped>
.empty {
  font-size: 12px;
  width: 48px;
  height: 24px;
  color: #666;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 12px;
  left: 71px;
  &:hover {
    border-color: #efefef;
    outline: none;
    box-shadow: none;
    background: #efefef;
    color: #888;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
